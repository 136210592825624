p{
    padding: 0 !important;
    margin: 0 !important;
}

.flex{
    display: flex;
}

.items-center{
    align-items: center;
}

.row{
    margin: 0 !important;
}

.quick-links-col{
    margin-top: 2rem;;
}

.footer{
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.095em;
    color: #FFFFFF;
}

.top-footer{
    background-color: #011c65;
    padding: 29px !important;
    margin: 0 !important;
}

.footer-section{
    border-bottom: 0.5px solid #FFFFFF;
}

.footer-section-last{
    border-bottom: none;
}

.company-desc{
    width: 100%;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF !important;
    font-family: poppins;
}

.footer-section-title{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-weight: 500;
}

.footer-section-title > h6{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.06em;
}

.footer-nav{
    margin: 0;
    padding: 0;
}

.footer-nav-items{
    margin: 0;
    padding: 12.5px 0;
    font-size: 10px;
    list-style-type: none;
}

.footer-nav-items>a{
    color: white;
}

.socials{
    display: flex;
}

.footer-contact{
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.095em;
}

.footer-contact>img{
    margin-right: 1rem;
}

.footer-contact.email{
    margin-top: 1rem !important;
}

.socials > div{
    margin-right: 16px;
}


.bottom-footer{
    background: #001651 !important;
    /* padding: 20px; */
    overflow: auto;
}

.bottom-div{
    padding: 16px 55px;
    text-align: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.095em;
}


.secondary-footer-nav{
    margin: 0; 
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.095em;
}

.secondary-footer-nav> li{
    list-style-type: none;
}

.secondary-footer-nav > li > a{
    color: white !important;
}

.footer-nav.socials{
    justify-content: space-between;
}

@media (min-width: 576px) {

}


@media (min-width: 768px) { 
    .footer{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.06em;
        color: white;
    }

    .footer-nav.nav-links{
        display: block !important;
    }

    .footer-nav.contact{
        display: block !important;
    }

    .footer-nav.socials{
        display: flex !important;
    }

    .footer-section-title > h6{
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .footer-dropdown-toggle{
        display: none;
    }

    .footer-nav-items{
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
    }

    .footer-contact{
        font-size: 12px;
    }

    .footer-section{
        margin-top: 37px;
        margin-right: 2rem;
        border-bottom: none;
    }

    .socials{
        display: block !important;
    }
    
    
}


@media (min-width: 992px) { 

    .top-footer{
        padding: 66px 78px 110px 78px !important;
    }

    .quick-links-col{
        margin-top: 2rem;;
    }

    .footer-section-title{
        margin-top: 0;
        padding-top: 0;
    }
    
    .company-desc-text{
        margin-top: 32px !important;
        margin-right: 30px !important;
        font-weight: 300;
        font-size: 16px !important;
        line-height: 30px;
        letter-spacing: 0.095em;
        color: white !important;
    }

    .footer-section-title{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.06em;
    }

    .footer-section-title > h6 {
        font-size: 18px;
        color: white;
    }
    
    .footer-nav-items{
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.095em;
        color: #FFFFFF !important;
    }

    .footer-nav-items a:hover{
        text-decoration: none;
    }

    .footer-contact{
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.095em;
    }

    .footer-section{
        margin: 0;
    }

    .bottom-div{
        padding: 25px 95px;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        text-align: left !important;
    }

    .secondary-footer-nav{
        margin: 0; 
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.095em;
    }

    .secondary-footer-nav {
        padding: 25px 15px;
    }

    .secondary-footer-nav > li{
        margin-left: 28px;
    }

    .bottom-footer{
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
    }
    
}


@media (min-width: 1200px) {
    
}