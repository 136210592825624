.Input, .Input-label{
    background-color: white;
    width: 320px;
    height: 50px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    margin: 20px 0px;
}

.Input-label {
    width: 95%;
    border-radius: 2px;
    border: none;
    box-shadow: 1px 1px 10px #ccc;
}

label {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #999;
    transition: font-size .5s;
}

label.active {
    font-size: 8px;
    top: 0px;
    padding: 5px 7px;
    background-color: #fff;
    color: #022295;
}

.label {
    position: relative !important;
    top: 10px !important;
    color: #4D4D4D;
    font-weight: 400;
}

.Input input, .Input-label input{
    position: absolute;
    top: 0;
    left: 0px;
    width: 80%;
    height: 100%;
    padding-left: 20px;
    background-color: transparent;
    border: none;
    outline: none;
}

.Input-label input{
    width: 90%;
}

.Input.active {
    box-shadow: 1px 1px 10px #999999;
}

.Input .icon{
    position: absolute;
    top: 10px;
    right: 10px;
}

.re-captcha {
    margin-bottom: 20px;
}

.g-recaptcha div:first-child{
    width: 100%;
}
.Input .Helper, .Input-label .Helper  {
    position: absolute;
    bottom: -20px;
    left: 0px;
    color: rgba(228, 33, 33, 0.808);
}