.navigation .navbar{
    padding: 0px 20px !important;
    background-color: white;
}

.navigation .navbar-brand{
    max-width: 50%;
}

.navigation .navbar-toggler{
    border: 0 !important;
    outline: none !important;
}

.header-nav-link{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: #022295 !important;
}

.header-nav-link.active::after {
    bottom: 7px !important;
}

.navigation .navbar-collapse{
    position: absolute;
    top: 45px;
    right: 0;
    left: 0;
    width: 100%;
    padding: 32px 27px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.62);
}

.auth-nav .btn-group .btn-primary {
    border-color: rgb(2, 24, 104);
}

.auth-nav .btn-group .btn-primary:hover {
    background-color: rgba(2, 24, 104) !important;
}

@media (min-width: 576px) {
    .navigation .navbar{
        display: flex;
        padding: 0px 43px !important;
        box-shadow: -1px 0px 3px 0px rgba(0, 0, 0, 0.62) !important;
    }

    .navigation .navbar-collapse{
        position: relative !important;
        width: 100%;
        padding: 0 !important;
        top: 0px;
        box-shadow: none;
    }

    .header-nav-link{
        font-size: 18px;
        line-height: 27px;
    }
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
    .navigation .navbar-collapse{
        position: relative !important;
        width: 100%;
        padding: 16px 43px;
    }

    .header-nav-link{
        font-size: 18px;
        line-height: 27px;
        margin-left: 39px;
    }
}

@media (min-width: 1200px) { 

 }