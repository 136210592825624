body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.recruit-img img{
  margin-top: 10%;
  width: 100%;
}

.have{
  font-family: Poppins-Light;
  font-size: 48px;
  color: #022295;
  padding-top: 19px;
  /* line-height: 96px; */
}

.got{
  font-family: Poppins-Bold;
  font-size: 48px;
  color: #022295;
}
.what{
  display: flex;
  flex-direction: column;
  display: inline-block;
}

.recruit-text{
  color: #022295;
  font-weight: 500;
  font-size: 28px;
}

.dash-text{
  font-family: Poppins-Lighter-Italic;
  font-size: 16px;
  color: #022295;
}

#career{
  font-weight: bold;
}
.passionate{
  font-family: Poppins-Light;
  font-style: normal;
  font-size: 18px;
  color: #4D4D4D;
  width: 90% !important;
}
.button-join{
  width: 200px;
  height: 72px;
  background-color: #022295;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.62);
  border-radius: 5px;
  border: none;
  color: white;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  margin-top: 20px;
}

.forgot-password {
  color: #022295;
  margin-top: 20px;
  display: block;
  /* text-align: right; */
  text-decoration: none;
  transition: font-weight 1s;
}

.forgot-password:hover {
  text-decoration: none;
  color: #022295;
  font-weight: bold;
}