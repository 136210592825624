.contain {
	padding-top: 10px !important;
}

.nav-link {
	display: block;
	padding: 1.5rem 1rem;
}
.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0rem !important;
	border-top-right-radius: 0rem !important;
	border-color: #dee2e6 #dee2e6 #dee2e6;
	color: #4d4d4d !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background-color: #e0e0e0;
}
.nav-tabs .nav-item.show .nav-link::after,
.nav-tabs .nav-link.active::after {
	content: none !important;
}
.nav-col {
	padding-right: 0px !important;
	padding-left: 0px !important;
}
.nav-col .nav-item {
	border-left: none !important;
}
.row-m-i {
	margin-left: -15px !important;
}
.big-box {
	height: auto;
	box-shadow: 1px 1px 10px #ccc;
}
.edit-box {
	background: #f2f2f2;
	box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	padding: 12px 30px;
	border: none;
	font-size: 12px;
	transition: background-color 0.5s;
	outline: none;
}

.edit-box:active,
.edit-box:focus {
	outline: none;
}

.edit-box:hover {
	background: #ccc;
}

.profile-img-section {
	position: relative;
	background: #aaa;
	margin-bottom: -20px;
	border-radius: 300px;
	width: 70px;
	height: 70px;
}

.profile-img {
	opacity: 0;
	display: block;
	width: 100%;
	height: auto;
	transition: 0.5s ease;
	backface-visibility: hidden;
}

.profile-img-section:hover .profile-img {
	opacity: 0.3;
}

.profile-img-section:hover .profile-img-center {
	opacity: 1;
}

.profile-img-center {
	transition: 0.5s ease;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}

.camera-icon-center {
	opacity: 0;
}

.camera-icon-center:hover {
	opacity: 0.4;
	transition: 1s ease;
	-ws-transform: scale(0.6);
	transform: scale(0.6);
}

.profile-img:hover {
	/* height: 1000px; */
	/* width: 100%; */
	background: rgba(0, 0, 0, 0.25);
	display: block;
	/* padding: 10px; */
}

.profile-div {
	position: absolute;
	width: 100%;
	height: 100%;
}
.image-border {
	border-radius: 50%;
}
.absolute-right {
	position: absolute;
	right: 6%;
	top: 10px;
}

.linkedIn-button {
	background: #007ce6;
	border-radius: 5px;
	padding: 10px;
	border: none;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	float: right;
}

.preview {
	width: 95%;
}

.preview span {
	display: inline-block;
	margin: 10px 0px;
	color: #777;
}

.hide {
	display: none;
	visibility: hidden;
}
.personal-box {
	font-weight: bold;
}

.option {
	position: absolute;
	top: 0px;
	right: 10px;
	display: flex;
	flex-direction: row;
}

.option span {
	display: flex;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	box-shadow: 1px 1px 10px #ccc;
	border-color: #f2f2f2;
	margin: 0px 5px;
	cursor: pointer;
	transition: background-color 0.5s;
}

.option span:hover {
	background-color: #ccc;
}

.listing {
	position: relative;
}

.custom-switch {
	padding-left: 0rem;
}
.custom-control {
	padding-left: 1rem;
}
.input-file {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 27px;
	color: #4d4d4d;
	background: #f2f2f2;
	box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	padding: 13px;
	cursor: pointer;
}

input[type="file"] {
	display: none;
}
.avatar {
	vertical-align: middle;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-image: url(../images/avater.png);
	background-repeat: no-repeat;
	background-size: cover;
}
.profile-img {
	height: 10px;
	position: relative;
}

.centralize {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.walk-through {
	position: relative;
	margin-top: 5%;
	padding: 0 2rem;
	width: 100%;
	min-height: 6rem;
}

.walk-through .next {
	position: absolute;
	top: 0;
	right: 2rem;
}

.walk-through .next,
.walk-through .back {
	background-color: #011c65;
	color: #e0e0e0;
}
