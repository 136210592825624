.primary-button{
    background: #022295;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border: none;
    transition: background .5s;
    outline: none !important;
}
.primary-button:hover {
    background: #031968;
}
.submit-button-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
}
.admin-button {
    width: 100%;
}

.admin-button .primary-button{
    border-radius: 60px;
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.25);
    padding: 16px 0px !important;
}