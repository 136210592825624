@font-face {
	font-family: Poppins;
	src: url(../fonts/Poppins/Poppins-Regular.ttf);
	font-display: swap;
}

@font-face {
	font-family: Poppins-Black-Italic;
	src: url(../fonts/Poppins/Poppins-BlackItalic.ttf);
	font-display: swap;
}

@font-face {
	font-family: Poppins-Bold;
	src: url(../fonts/Poppins/Poppins-Bold.ttf);
	font-display: swap;
}

@font-face {
	font-family: Poppins-Italic;
	src: url(../fonts/Poppins/Poppins-Italic.ttf);
	font-display: swap;
}

@font-face {
	font-family: Poppins-Light;
	src: url(../fonts/Poppins/Poppins-Light.ttf);
	font-display: swap;
}

@font-face {
	font-family: Poppins-Lighter;
	src: url(../fonts/Poppins/Poppins-ExtraLight.ttf);
	font-display: swap;
}

@font-face {
	font-family: Poppins-Lighter-Italic;
	src: url(../fonts/Poppins/Poppins-LightItalic.ttf);
	font-display: swap;
}

.contain {
	padding: 61px 46px;
}

.contain {
	margin: 10% 0px;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(36deg);
	}
	20% {
		transform: rotate(72deg);
	}
	30% {
		transform: rotate(108deg);
	}
	40% {
		transform: rotate(144deg);
	}
	50% {
		transform: rotate(180deg);
	}
	60% {
		transform: rotate(216deg);
	}
	70% {
		transform: rotate(252deg);
	}
	80% {
		transform: rotate(288deg);
	}
	90% {
		transform: rotate(324deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.rotate {
	animation-name: rotate;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
