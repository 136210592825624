.swiper-container {
	width: 100%;
	height: 50vh;
}

.slider-1 {
	background: url("/images/slides/slide-1-min.png");
	background-color: #f2994a;
	background-blend-mode: multiply;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.slider-2 {
	background: url("/images/slides/slide-2-min.png");
	background-color: #027a95;
	background-blend-mode: multiply;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.slider-3 {
	background: url("/images/slides/slide-3-min.png");
	background-color: #2f80ed;
	background-blend-mode: multiply;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.slider-text-section {
	padding: 30px;
	background: rgb(0, 0, 0, 0.8);
}

.yellow-slider-text {
	font-size: 15px;
	font-weight: 700;
	line-height: 42px;
	letter-spacing: 0.095em;
	color: #ff9b00;
}

.white-slider-text,
.white-slider-text h2 {
	margin-top: -10px;
	position: relative;
	font-size: 13px;
	font-weight: bold;
	color: #ffffff;
}

.africa-text {
	display: inline-block;
	padding-right: 22px !important;
	padding-left: 30px !important;
	background: #c9092e;
	font-size: 15px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.africa-section img {
	margin-right: -28px;
	z-index: 9;
}

.africa-section h4 {
	display: inline-block;
	line-height: 42px;
	color: #ffffff;
}

p.slider-description {
	margin-top: 30px;
	font-size: 13px;
	line-height: 30px !important;
	text-align: justify;
	color: #ffffff !important;
}

@media (min-width: 767.98px) {
	.swiper-pagination-container {
		position: relative;
	}

	.swiper-container {
		width: 100%;
		height: 90vh;
	}

	.swiper-pagination {
		position: absolute;
		display: flex;
		flex-direction: column;
		bottom: 0 !important;
		right: 0px !important;
		left: auto !important;
		margin-top: 0;
		top: -80vh !important;
	}

	span.swiper-pagination-bullet.swiper-container-bullet-active {
		position: absolute;
		width: 20px;
		right: 0;
	}

	span.swiper-pagination-bullet {
		display: block;
		background-color: white;
		width: 2px;
		height: 20vh;
		border-radius: 0;
	}

	span.swiper-pagination-bullet-active {
		background-color: white;
		height: 20vh;
		border-radius: 0;
		padding-right: 3px !important;
		padding-left: 2px !important;
		margin-left: -1px !important;
	}

	.slider-text-section {
		max-width: 60%;
		background: transparent;
	}

	.yellow-slider-text {
		margin-bottom: -25px;
		font-weight: normal;
		font-size: 28px;
		line-height: 42px;
		letter-spacing: 0.095em;
	}

	.white-slider-text,
	.white-slider-text h2 {
		margin-top: 10px;
		position: relative;
		font-weight: bold;
		font-size: 72px;
		color: #ffffff;
	}

	.africa-text {
		display: inline-block;
		margin-top: -15px;
		padding-right: 22px !important;
		padding-left: 30px !important;
		background: #c9092e;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	.africa-section img {
		margin-right: -28px;
		margin-top: -15px;
		z-index: 9;
	}

	.africa-section h4 {
		display: inline-block;
		font-size: 28px;
		line-height: 42px;
		letter-spacing: 0.095em;
		color: #ffffff;
	}

	p.slider-description {
		font-size: 24px !important;
		line-height: 36px !important;
		letter-spacing: 0.095em !important;
		color: #ffffff !important;
	}
}

@media (min-width: 1024px) {
	.swiper-pagination {
		right: -5px !important;
	}
}

@media (min-width: 1440px) {
	.swiper-pagination {
		right: -120px !important;
	}
}

@media (min-width: 2560px) {
	.swiper-pagination {
		right: -620px !important;
	}
}
