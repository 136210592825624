.heading{
    position: relative;
    text-transform: uppercase;
    font-family: Poppins;
    font-style: normal;
    font-weight: light;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.095em;
    color: #022295;
}

.headingBold{
    font-weight: 600;
}

.paycentreSvg{
    height: 248px;
    width: 100%;
    margin: 0 auto;
}

.main-home-section p{    
    text-align: justify;
    font-size: 12px;
    letter-spacing: 0.095em;
    color: #4F4F4F;
}

.visit-button{
    display: none;
}

.light-blue-bg{
    background: #F4FBFF;
}

.payPadSvg{
    height: 248px;
    width: 100%;
    margin: 0 auto;
}

.second-product-div p{
    text-align: justify;
    font-size: 12px;
    letter-spacing: 0.095em;
    color: #4F4F4F;
}

.cashEnvoySvg{
    height: 248px;
    width: 100%;
    margin: 0 auto;
}

.blog-section-heading{
    width: 100%;
    margin: 0 auto;
}

.blog-image{
    width: 100%;
    margin: 0 auto;
}

.blog-section-heading p{
    margin-top: 20px;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    letter-spacing: 0.055em;
    color: #4F4F4F;
}

.blog-card{
    background: white;
    box-shadow: 0px 0.2px 25px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.blog-card-head{
    height: 260px;
    overflow: hidden;
    background: #EAF7FF;
    border-radius: 12px 12px 0px 0px;
}

.blog-card-head img{
    height: 100%;
}

.blog-card-body{
    padding: 22px;
    background: white;
    border-radius: 0px 0px 12px 12px;
}

.blog-card-date{
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.095em;
}

.blog-card-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.095em;
}

.blog-card-bodytext{
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.095em;
    color: #4F4F4F;
}

.blog-button-area{
    display: flex;
    justify-content: flex-end;
}

.blog-button{
    margin-top: 30px;
    width: 45px;
    height: 45px;
    background: #022295;
    border-radius: 43px;
    border: 0px;
}

@media (min-width: 768px) {
    .heading{
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.095em;
    }
    
    .headingBold{
        font-weight: 600;
    }

    .main-home-section p{
        font-size: 14px;
        line-height: 21px;
        text-align: justify;
        letter-spacing: 0.095em;
    }

    .visit-button{
        display: inline-block;
        background: #022295;
        border-radius: 43px;
        margin-top: 11px;
        padding: 10px 20px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: white;
    }

    .visit-button:hover{
        text-decoration: none;
        background: #344EAA;
        color: white;
    }
}

@media (min-width: 425px) and (max-width: 990px){
    .blog-card-col{
        flex: 0 0 320px !important;
        max-width: 320px !important;
    }

    .blog-row {
        flex-direction: row !important;
        overflow-x: scroll !important;
        flex-wrap: nowrap !important;
    }
}

@media (min-width: 992px) {
    
}

@media (min-width: 1200px) { 

    .heading{
        font-size: 24px;
        line-height: 36px;
    }

    .main-home-section .py-4{
        padding: 106px !important;
    }
    .main-home-section p{
        margin-top: 35px;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.095em;
    }

    .visit-button{
        width: 222px;
        margin-top: 35px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.095em;
    }

    .blog-section-heading p{
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 15px !important;
    }

    .blog-card *{
        font-size: 16px;
        line-height: 24px;
    }

    .blog-card-date{
        font-family: Poppins;
        font-style: 300;
    }

    .blog-card-title{
        font-weight: 600;
    }
}