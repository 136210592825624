.modal-background{
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 999;
}

.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 20%;
    left: 50%;
    width: 500px;
    height: 470px;
    margin-left: -270.5px !important;
    background: white;
    padding: 40px 15px;
    overflow-y: auto;
    /* max-height: 90%; */
}

.modal-heading-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #4D4D4D;
}

.modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-y: scroll; */
}

.modal-body p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #4D4D4D;
}

.modal-body img{
    height: 150px;
    padding-bottom: 25px !important;
    margin: 0 auto;
    width: 150px;
}


@media (max-width: 575.98px) { 
    .modal{
        top: 30%;
        width: 320px;
        height: 350px;
        margin-left: -160px !important;
        padding: 20px !important;
    }

    .modal-heading-text{
        font-size: 30px;
        line-height: 28px;
    }

    .modal-body{
        height: 350px !important;
    }

    .modal-body img{
        width: 40% !important;
    }

    .modal-body p{
        font-size: 16px;
        line-height: 23px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) { 
    .modal{
        top: 15%;
        width: 450px;
        height: 430px;
        margin-left: -225px !important;
        padding: 30px !important;
    }

    .modal-heading-text{
        font-size: 31px;
        line-height: 28px;
    }

    .modal-body img{
        width: 40% !important;
    }

    .modal-body p{
        font-size: 18px;
        line-height: 25px;
    }
}